<script src="@/api/index.js"></script>
<template>
	<div class="container">
		<div class="titleimg">
			<img src="@/assets/img/login_title.png">
		</div>
		<!-- 中间背景 -->
		<div class="centent-background">
			<!-- <img class="yunduo a" src="@/assets/img/yun.png"> -->
			<!-- <img class="yunduo c" src="@/assets/img/content.png"> -->
		</div>
		<div class="btn">
			<div class="btn_style btn_37" @click="handleClick(1)">
				<img style="width: 100%;" src="@/assets/img/login.png" alt="">
				<!-- 登录 -->
			</div>
			<div class="flex">
				<div class="btn_style btn_32" @click="simulationClick(2)">
					<!-- 模拟答题 -->
					<img style="width: 100%;" src="@/assets/img/simulation.png" alt="">
				</div>
				<div class="btn_style btn_32" @click="handleClick(2)">
					<!-- 生成奖状 -->
					<img style="width: 100%;" src="@/assets/img/awards.png" alt="">
				</div>
			</div>
			<div class="flex">
				<div class="btn_style btn_32">
					<!-- TOP风云榜 -->
					<img style="width: 100%;" src="@/assets/img/TOP.png" alt="">
				</div>
				<div class="btn_style btn_32">
					<!-- 参赛奖励 -->
					<img style="width: 100%;" src="@/assets/img/reward.png" alt="">
				</div>
			</div>
			<div class="flex tops">
				<div class="btn_style top" @click="topClick('/paihang')"></div>
				<div class="btn_style top" @click="show = true"></div>
			</div>
		</div>
		<div class="bottom">
			<div class="title_p">
				<div class="imgs">
					<img src="../../assets/img/zbfw.png" alt="">
				</div>
				<!-- <div class="title">主办单位：深圳市龙岗区教育局</div>
				<div class="title">承办单位：深圳市龙岗区教育发展中心</div>
				<div class="title">协办单位：深圳市龙岗区青少年科技教育协会</div> -->
			</div>
			<div class="counts">现已有{{ count }}人参加活动</div>
		</div>

		<van-overlay :show="show" @click="show = false">
			<div class="popup_box">
				<!-- <img src="#" alt="" class="bj_img"> -->
				<img src="../../assets/img/joingif.png" style="width: 100%;" alt="">
				<!-- <div class="popup_box_border">
					<div class="popup_box_border_title">1、参赛者（师生）奖项</div>
					<div class="popup_box_border_text">
						本次比赛会评选特等奖（100分）、一等奖（100分＞分值≥90分）、二等奖（90分＞分值≥80分）、三等奖（80分＞分值≥70分）若干；
					</div>
					<div class="popup_box_border_title">2、教师奖项</div>
					<div class="popup_box_border_text">
						根据教师自身参赛成绩，超过70分以上的，可申请获得3个继续教育学时。
					</div>
					<div class="popup_box_border_title">3、单位奖项</div>
					<div class="popup_box_border_text">
						优秀组织单位50个、优秀组织工作者100名。
					</div>
				</div> -->
			</div>
		</van-overlay>
	</div>
</template>

<script>
	import {
		getNum,
	} from '@/api'
	export default {
		name: "index",
		data() {
			return {
				count: 0,
				show: false
			}
		},
		watch: {
			count(value) {
				// console.log(value)
				this.count = value
			}
		},
		created() {

		},
		mounted() {
			/* if(localStorage.getItem('seconds')){
				// console.log("you");
				localStorage.removeItem('seconds')
			} */
			this.get_Num()
		},
		methods: {
			get_Num() {
				getNum().then(res => {
					console.log(res)
					this.count = res.data.data.count
				})
			},
			// 登录
			handleClick(type) {
				if (type == 2) {
					let date = new Date(),
						localeString = date.toLocaleString(), //当前时间
						startTime = '2022/6/1 09:00:00',
						endTime = '2022/6/5 18:00:00'
					if (localeString < startTime) {
						this.$toast("领奖时间未开始")
					} else if (localeString < endTime) {
						this.$toast("领奖时间已结束")
					} else {
						this.$router.push({
							name: 'login',
							query: {
								type
							}
						})
					}
				} else {
					this.$router.push({
						name: 'login',
						query: {
							type
						}
					})
				}
			},
			simulationClick(id) {
				this.$router.push({
					name: "dati",
					query: {
						id: id
					}
				})
			},
			//生成奖状
			awardsClick() {
				this.$router.push('/awards/userInfo')
			},
			//top风云榜
			topClick(url) {
				console.log(1111)
				this.$router.push(url)
			}




			// formClick(e) {
			//   Dialog.confirm({
			//     message: '请确认信息是否无误',
			//   })
			//     .then(() => {
			//       getRegister(e).then(res => {
			//         if (res.data.code == 0) {
			//           this.$toast.fail(res.data.msg)
			//         } else {
			//           localStorage.setItem('token', res.data.data.userinfo.token)
			//           this.$toast.success(res.data.msg)
			//           setTimeout(() => {
			//             this.$router.push('/notice')
			//           }, 500)
			//         }
			//       })
			//     })
			//     .catch(() => {
			//       // on cancel
			//     })
			// },
			// handleUpload() {
			//   if (localStorage.getItem('token') == null) {
			//     this.$toast.fail('请登录后操作!')
			//     return
			//   }
			//   uploadData().then(res => {
			//     console.log(res)
			//     if (res.data.code == 0) {
			//       this.$toast.fail(res.data.msg)
			//     } else {

			//       this.show2 = true
			//       /*a.download = res.data.data
			//       a.url = res.data.data
			//       a.style.display = 'none'*/
			//       this.src = 'https://api.qingshaoniandati.kc87.com' + res.data.data
			//     }
			//   })
			// }
		}
	}
</script>
<style scoped lang="scss">
	.container {
		width: 100%;
		height: 100%;
		min-height: 100vh;
		background: url(../../assets/img/indexbg.png) no-repeat center;
		background-size: 100% 100%;
		position: relative;

		.titleimg {
			text-align: center;
			position: relative;
			z-index: 2;

			img {
				width: 75%;
				margin-top: 80px;
			}
		}

		.centent-background {
			width: 100%;
			text-align: center;
			position: absolute;
			top: 50px;

			.yunduo {
				width: 95%;
				height: 100%;
			}

			.a {
				position: relative;
				z-index: 1;
			}

			.c {
				// height: 10rem;
				margin-top: -250px;
			}
		}

		.counts {
			width: 100%;
			text-align: center;
			position: absolute;
			bottom: 10px;
			left: 50%;
			transform: translate(-50%, -50%);
			font-size: 35px;
			color: #fff;
			font-weight: 600;
		}

		.img_text {
			position: absolute;
			width: 100%;
			top: 75px;

			img {
				width: 100%;
				display: block;
				object-fit: cover;
			}
		}

		.btn {
			position: absolute;
			bottom: 300px;
			text-align: center;
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
		}

		.flex {
			width: 80%;
			display: flex;
			justify-content: space-between;
			margin-bottom: 20px;

			&::before {
				content: '';
				display: block;
			}

			&:after {
				content: '';
				display: block;
			}
		}


		.tops {
			margin-top: -110px;
			position: relative;
			z-index: 999;
		}

		.btn_style {
			width: 280px;
			height: 90px;
			line-height: 90px;
			border-radius: 20px;
			color: #fff;
			font-weight: bolder;
			font-family: "微软雅黑";
			letter-spacing: 6px;
			text-indent: 6px;
		}

		.btn_37 {
			position: relative;
			width: 350px;
			font-size: 65px;
			margin-bottom: 15px;
			z-index: 3;
			// margin-left: -200px;
			// color: #fff;
			// -webkit-text-stroke: 3.54px #1E4F83;
			// border: 5px solid #348DC7;
			// letter-spacing: 30px;
			// text-indent: 30px;
			// background-color: #FFFFFF;
			// background-image: -webkit-linear-gradient(180deg, #FFFFFF 6%, #D9EAFE 50%, #ffffff 96%);
			// background-image: -moz-linear-gradient(180deg, #FFFFFF 6%, #D9EAFE 50%, #ffffff 96%);
			// background-image: -o-linear-gradient(180deg, #FFFFFF 6%, #D9EAFE 50%, #ffffff 96%);
			// background-image: linear-gradient(180deg, #FFFFFF 6%, #D9EAFE 50%, #ffffff 96%);
		}

		.btn_32 {
			font-size: 45px;
			// color: #fff;
			// -webkit-text-stroke: 2.5px #1E4F83;
			// background-color: #FFFFFF;
			// background-image: -webkit-linear-gradient(180deg, #FFFFFF 6%, #D9EAFE 50%, #ffffff 96%);
			// background-image: -moz-linear-gradient(180deg, #FFFFFF 6%, #D9EAFE 50%, #ffffff 96%);
			// background-image: -o-linear-gradient(180deg, #FFFFFF 6%, #D9EAFE 50%, #ffffff 96%);
			// background-image: linear-gradient(180deg, #FFFFFF 6%, #D9EAFE 50%, #ffffff 96%);
			// box-shadow: -8px 9px 5px -3px rgba(77, 126, 148, 0.6);
			// -webkit-box-shadow: -8px 9px 5px -3px rgba(77, 126, 148, 0.6);
			// -moz-box-shadow: -8px 9px 5px -3px rgba(77, 126, 148, 0.6);
		}

		.form_box {
			padding: 48px 24px 48px 25px;
			width: 100%;
			box-sizing: border-box;
		}

		.bottom {

			.title_p {
				position: absolute;
				bottom: 150px;
				left: 0;
				right: 0;
				margin-left: auto;
				margin-right: auto;
				width: 80%;
				.imgs {
					// width: 100%;
					padding: 0 15px;

					img {
						width: 100%;
					}
				}

				/* .title {
					font-size: 24px;
					color: #21601A;
					text-align: center;
					font-weight: 600;
					margin-bottom: 10px;
				} */
			}
		}

		.van-overlay {
			z-index: 3 !important;
		}

		.popup_box {
			// background: #5689ff;
			padding: 15px;
			width: 90%;
			position: relative;
			top: 50%;
			left: 50%;
			border-radius: 10px;
			transform: translate(-50%, -50%);

			.bj_img {
				position: absolute;
				top: -80px;
				width: 470px;
				left: 50%;
				transform: translate(-50%, -50%);
			}

			.popup_box_border {
				// background: url("../../assets/img/jli.png") no-repeat center 0;
				background-size: cover;
				height: 100%;
				width: 100%;
				border: 2PX dashed #9fbefc;
				font-size: 35px;
				color: #fff;
				box-sizing: border-box;
				padding: 60px 30px;

				&_title {
					font-family: 'adobe 黑体 std';
					font-weight: 400;

					img {
						width: 38px;
						height: 38px;
						margin-right: 20px;
					}
				}

				&_text {
					font-size: 28px;
					// font-family: 'adobe 黑体 std';
					text-align: justify;
				}

				div {
					margin-bottom: 20px;
				}
			}
		}
	}
</style>
